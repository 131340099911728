<template>
  <custom-button
    class="flex items-center"
    size="none"
    is-link
    pill
    :href="link"
    target="_blank"
  >
    <nuxt-icon
      name="common/book-viewing"
      class="inline text-[20px]"
    />
    <span
      class="ml-2 font-semibold text-base"
      :class="spanClass"
    >{{ $t('suggestions.buttons.bookViewing.label') }}</span>
  </custom-button>
</template>

<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  spanClass: {
    type: [String, Object, Array],
    default: '',
  },
  mobilePhone: {
    type: String,
    required: true,
  },
  textToSend: {
    type: String,
    default: '',
  },
})

const link = `https://wa.me/${props.mobilePhone}?text=${props.textToSend}`
</script>
