<template>
  <div class="flex items-center gap-[12px]">
    <custom-avatar
      :alt="organisation.name ?? ''"
      :src="managerImageLink"
      :size="avatarSize"
      rounded
    />
    <div>
      <div class="text-neutral-900 font-semibold leading-5">
        {{ organisation.name }}
      </div>
      <div class="flex items-center">
        <span class="text-neutral-500 font-medium text-lg leading-6"> {{ organisation.phone }} </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomAvatar from '~/ui/avatars/CustomAvatar.vue'
import { Organisation } from '~/common/types/organisation/Organisation'
import { Size } from '~/ui/types/types'
import { SIZE } from '~/ui/constants/size'

const props = defineProps({
  organisation: {
    type: Object as PropType<Organisation>,
    required: true,
  },
  avatarSize: {
    type: String as PropType<Size>,
    default: SIZE.sm,
  },
})

const runtimeConfig = useRuntimeConfig()

const { quickDealAppUrl } = runtimeConfig.public

const managerImageLink = computed(() => (props.organisation.photo ? `${quickDealAppUrl}/img/org/logo/${props.organisation.id}/${props.organisation.photo}` : ''))

</script>
